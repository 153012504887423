import { SET_LANG, SET_PROFILE,SET_COUNTRY} from './type'
import { getLang, getProfile } from '@service/api'
import i18n from '@/locale'

export default {
    async setLang({ commit }, lang) {
        try {
            if (!lang) {
                // lang = localStorage.getItem('gowow_lang')
                // if (!lang) {
                //     ({ lang } = await getLang() || {})
                // }
                ({ lang } = await getLang() || {})
            }
            // lang = 'zh-CN'
            i18n.locale = lang
            commit(SET_LANG, lang)
            //对于非中文，使用Helvetica字体，因为逐个改动原有的字体较为繁琐，所以用该方法给除去icon的每一个元素添加字体样式，后面有更好的方法可以替代
            if (lang !== 'zh-CN') {
                document.querySelectorAll('body *:not(i)').forEach((element) => {
                    element.style.setProperty('font-family', 'Helvetica', 'important');
                });
            }
            // localStorage.setItem('gowow_lang', lang)
        } catch (e) {
            commit(SET_LANG, 'zh-CN')
        }
    },
    async setProfile({ commit }, profile) {
        try {
            if (!profile) {
                profile = await getProfile() || {}
            }
            commit(SET_PROFILE, profile)
        } catch (e) {
            commit(SET_PROFILE, {})
        }
    },
    setCountry({ commit }, country) {
        commit(SET_COUNTRY, country)
    }
}